import { getIETFEmailSchema } from 'libs'
import { z } from 'zod'
import { FormErrorsMessages } from '@/shared/constants'
import {
  addressSchema,
  mobileAndHomePhoneCubanSchema,
  userSchema,
} from '@/shared/schema'
import { luhnChk } from '@/shared'

const extraInfo = z.object({
  homePhone: mobileAndHomePhoneCubanSchema.or(z.literal('')).optional(),
  phone: mobileAndHomePhoneCubanSchema,
  nautaAccount: z
    .string()
    .regex(/^[a-zA-Z0-9\s]*$/, {
      message: FormErrorsMessages.Invalid,
    })
    .pipe(
      getIETFEmailSchema({
        invalidMessage: FormErrorsMessages.InvalidEmail,
      })
    )
    .or(z.literal(''))
    .optional()
    .nullable(),
  identityCardNumber: z
    .string({ required_error: FormErrorsMessages.Required })
    .min(1, { message: FormErrorsMessages.Required })
    .refine(
      (value) =>
        /^\d{2}(?<temp2>0[1-9]|1[0-2])(?<temp1>0[1-9]|[12][0-9]|3[01])\d{5}$/.test(
          value
        ),
      {
        message: FormErrorsMessages.Invalid,
      }
    ),
})

export const contactInfoSchema = userSchema
  .omit({
    gender: true,
    consent: true,
    dateOfBirth: true,
    email: true,
    phone: true,
  })
  .merge(extraInfo)
  .merge(addressSchema.omit({ country: true, postalCode: true }))
  .merge(
    z.object({
      email: z
        .string()
        .email({
          message: FormErrorsMessages.InvalidEmail,
        })
        .pipe(
          getIETFEmailSchema({
            invalidMessage: FormErrorsMessages.InvalidEmail,
            requiredMessage: FormErrorsMessages.Required,
          })
        )
        .or(z.literal(''))
        .optional()
        .nullable(),
      postalCode: z
        .string({ required_error: FormErrorsMessages.Required })
        .regex(/^[0-9\s]{5}$/, {
          message: FormErrorsMessages.Invalid,
        })
        .or(z.literal(''))
        .optional()
        .nullable(),
      secondSurname: z
        .string({ required_error: FormErrorsMessages.Required })
        .min(1, {
          message: FormErrorsMessages.Required,
        })
        .refine((value) => /^[^\d]{1,80}$/.test(value), {
          message: FormErrorsMessages.MaxLength80,
        }),
      secondName: z
        .string()
        .refine((value) => value === '' || /^[^\d]{1,80}$/.test(value), {
          message: FormErrorsMessages.MaxLength80,
        })
        .optional()
        .nullable(),
    })
  )
  .superRefine((data, ctx) => {
    if (data.phone && data.phone === data.homePhone) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: FormErrorsMessages.PhoneEqualHomePhone,
        path: ['homePhone'],
      })
    }
  })

export const addContactCardSchema = z.object({
  accountNumber: z
    .string({ required_error: FormErrorsMessages.Required })
    .refine((accounNumber) => luhnChk({ input: accounNumber, limit: 16 }), {
      message: FormErrorsMessages.InvalidCard,
    }),
})
