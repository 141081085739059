import { request, responseAdapter } from 'libs'
import { getHeaders, type HeaderRequest } from '@/shared'
import type {
  PaymentMethodCard,
  PaymentMethodRequest,
  PaymentMethodResponse,
  PaymentMethodResponseOnAdd,
} from './payment-methods.types'

export const getPaymentMethods = async ({
  accessToken,
  locale = 'es',
}: HeaderRequest): Promise<PaymentMethodResponse<PaymentMethodCard[]>> => {
  const response = await request.get(
    '/profile/cards',
    getHeaders({ locale, accessToken })
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const addPaymentMethod = async ({
  accessToken,
  paymentMethod,
}: {
  paymentMethod: PaymentMethodRequest
  accessToken?: string
}): Promise<PaymentMethodResponseOnAdd<PaymentMethodCard>> => {
  const response = await request.post(
    '/profile/cards',
    { ...paymentMethod, cvv: paymentMethod.cvc },
    getHeaders({ accessToken })
  )

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const deletePaymentMethod = async ({
  id,
  accessToken,
}: {
  id: number
  accessToken?: string
}): Promise<PaymentMethodResponse<PaymentMethodCard>> => {
  const response = await request.delete(
    `/profile/cards/${id}`,
    getHeaders({ accessToken })
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const revalidatePaymentMethod = async ({
  cardId,
  cvv,
  cardNumber,
  accessToken,
}: {
  cardId: number
  cvv: string
  cardNumber: string
  accessToken?: string
}): Promise<PaymentMethodResponse<PaymentMethodCard>> => {
  const response = await request.post(
    `/profile/cards/revalidate/${cardId}`,
    { cardNumber, cvv },
    getHeaders({ accessToken })
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}
