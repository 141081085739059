import type { AdditionalBaseResponse } from 'libs'
import { request, responseAdapter } from 'libs'
import { getHeaders } from '@/shared'
import type { HeaderRequest } from '@/shared/types'
import type {
  AccountResponse,
  Contact,
  ContactApiResponse,
  ContactRequest,
  ContactResponse,
  CreateAccountResponse,
  FormInfo,
  GetOneContactResponse,
} from './contacts.types'

export const getContacts = async ({
  accessToken,
  locale = 'es',
}: HeaderRequest): Promise<
  AdditionalBaseResponse<ContactResponse<Contact[]>>
> => {
  const response = await request.get(
    '/contacts',
    getHeaders({ locale, accessToken })
  )

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const addContact = async ({
  contact,
  accessToken,
}: {
  contact: FormInfo
  accessToken?: string
}): Promise<ContactRequest<Contact>> => {
  const response = await request.post(
    '/contacts',
    { ...contact },
    getHeaders({ accessToken })
  )

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const updateContact = async ({
  contact,
  accessToken,
}: {
  contact: Contact
  accessToken?: string
}): Promise<ContactRequest<Contact>> => {
  const response = await request.patch(
    `/contacts/${contact.id}`,
    { ...contact },
    getHeaders({ accessToken })
  )

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const deleteContact = async ({
  id,
  accessToken,
}: {
  id: number
  accessToken?: string
}): Promise<ContactApiResponse<boolean>> => {
  const response = await request.delete(
    `/contacts/${id}`,
    getHeaders({ accessToken })
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const createContactAccount = async ({
  contactId,
  accountNumber,
  accessToken,
}: {
  contactId: number
  accountNumber: string
  accessToken?: string
}): Promise<CreateAccountResponse> => {
  const response = await request.post(
    `/contacts/accounts/${contactId}`,
    { accountNumber },
    getHeaders({ accessToken })
  )

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const getContactAccount = async ({
  contactId,
  currencyReceive,
  accessToken,
}: {
  contactId: number
  currencyReceive: string
  accessToken?: string
}): Promise<AccountResponse> => {
  const response = await request.get(
    `/contacts/accounts/${contactId}/?currencyReceive=${currencyReceive}`,
    getHeaders({ accessToken })
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const deleteContactAccount = async ({
  id,
  contactId,
  accessToken,
}: {
  id: number
  contactId: number
  accessToken?: string
}): Promise<AccountResponse> => {
  const response = await request.delete(
    `/contacts/accounts/${contactId}/${id}`,
    getHeaders({ accessToken })
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const getOneContact = async (params: {
  accessToken?: string
  contactId: string
  locale?: string
}): Promise<GetOneContactResponse> => {
  const { contactId, accessToken, locale = 'es' } = params
  const url = `/contacts/${contactId}`
  const response = await request.get(url, {
    cache: 'no-store',
    headers: getHeaders({ accessToken, locale }).headers,
  })

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}
