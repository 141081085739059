import * as z from 'zod'
import { addressSchema } from '@/shared/schema'
import { FormErrorsMessages } from '@/shared/constants'
import { luhnChk } from '@/shared'

export const paymentMethodCardSchema = z
  .object({
    cardNumber: z.string().refine(
      (cardNumber) => {
        return (
          luhnChk({ input: cardNumber }) &&
          (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cardNumber.replaceAll(' ', '')) || // Visa
            /^5[1-5][0-9]{14}$/.test(cardNumber.replaceAll(' ', '')) || // MasterCard
            /^3[0-9]{14}$/.test(cardNumber.replaceAll(' ', '')))
        )
      },
      {
        message: FormErrorsMessages.InvalidCard,
      }
    ),
    expirationDate: z.string({
      required_error: FormErrorsMessages.Required,
      invalid_type_error: FormErrorsMessages.Required,
    }),
    cardHolder: z
      .string({
        required_error: FormErrorsMessages.Required,
      })
      .trim()
      .min(1, { message: FormErrorsMessages.Required })
      .refine((value) => value.split(' ').length > 1, {
        message: FormErrorsMessages.InvalidCardHolder,
      })
      .refine((value) => /^[^\d]{1,80}$/.test(value), {
        message: FormErrorsMessages.MaxLength80,
      }),
  })
  .merge(addressSchema)

export const addPaymentMethodCardSchema = (cardType: string) => {
  const cvvSchema = z.object({
    cvv: z.string().refine(
      (value) => {
        if (cardType === 'amex') {
          return value.length === 4
        }
        return value.length === 3
      },
      {
        message: FormErrorsMessages.InvalidCVV,
      }
    ),
  })

  return paymentMethodCardSchema.merge(cvvSchema)
}

export const revalidateCardSchema = z.object({
  cardNumber: z
    .string()
    .min(1, {
      message: FormErrorsMessages.Required,
    })
    .regex(/^(?<temp1>\d{4} ){3}\d{4}$/, {
      message: FormErrorsMessages.InvalidCard,
    }),
  cvv: z
    .string()
    .min(1, {
      message: FormErrorsMessages.Required,
    })
    .min(3, {
      message: FormErrorsMessages.InvalidCVV,
    })
    .max(3, {
      message: FormErrorsMessages.InvalidCVV,
    }),
})
