import { create } from 'zustand'
import type { PaymentMethodCard } from './payment-methods.types'

export interface PaymentMethodsState {
  cards: PaymentMethodCard[]
  allowToAddMore: boolean
  activeCard: PaymentMethodCard | null
}

export interface PaymentMethodsAction {
  addPaymentMethodCard: (paymentMethodCard: PaymentMethodCard) => void
  addCards: (paymentMethodCard: PaymentMethodCard[]) => void
  updateAllowToAddMore: (allowToAddMore: boolean) => void
  updateActiveCard: (activeCard: PaymentMethodCard) => void
}

export const usePaymentMethodStore = create<
  PaymentMethodsState & PaymentMethodsAction
>()((set) => ({
  cards: [],
  allowToAddMore: true,
  activeCard: null,
  addPaymentMethodCard: (paymentMethodCard) => {
    set((state) => ({
      cards: [paymentMethodCard, ...state.cards],
    }))
  },
  addCards: (cards) => {
    set(() => ({
      cards,
    }))
  },
  updateAllowToAddMore: (allowToAddMore) => {
    set(() => ({
      allowToAddMore,
    }))
  },
  updateActiveCard: (activeCard) => {
    set(() => ({
      activeCard,
    }))
  },
}))
