import type { AdditionalBaseResponse } from 'libs'
import { isEmpty, request, responseAdapter } from 'libs'
import { getHeaders, type HeaderRequest } from '@/shared'
import type {
  GetFeeResponse,
  GetOfacResponse,
  GetServiceResponse,
  Remittance,
  RemittanceConfigResponse,
  RemittanceFeeRequest,
  RemittanceRequest,
  RemittanceResponse,
  RemittanceServiceRequest,
  RemittanceData,
} from './remittance.type'

export const getRemittanceServices = async ({
  cityId,
  stateId,
  accessToken,
}: RemittanceServiceRequest): Promise<GetServiceResponse> => {
  const response = await request.get(
    `/remittances/services?countryId=56&stateId=${stateId}&cityId=${cityId}`,
    getHeaders({ accessToken })
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const getFee = async ({
  serviceId,
  amount,
  accessToken,
}: RemittanceFeeRequest): Promise<GetFeeResponse> => {
  const response = await request.get(
    `/remittances/fee?serviceId=${serviceId}&amount=${amount}`,
    getHeaders({ accessToken })
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const getOfac = async ({
  accessToken,
  locale = 'es',
}: HeaderRequest): Promise<GetOfacResponse> => {
  const response = await request.get(
    `/remittances/ofac`,
    getHeaders({ accessToken, locale })
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const postRemittance = async ({
  remittance,
  accessToken,
}: {
  remittance: RemittanceRequest
  accessToken?: string
}): Promise<RemittanceResponse<Remittance>> => {
  const response = await request.post(
    `/remittances`,
    remittance,
    getHeaders({ accessToken })
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const getRemittanceConfig = async ({
  accessToken,
  locale = 'es',
}: HeaderRequest): Promise<
  AdditionalBaseResponse<RemittanceConfigResponse>
> => {
  const response = await request.get(
    `/remittances/config`,
    getHeaders({ accessToken, locale })
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const getRemittancesHistory = async ({
  locale,
  accessToken,
  queryParams,
}: HeaderRequest & {
  queryParams: {
    order?: 'ASC' | 'DESC'
    page?: number
    take?: number
  }
}): Promise<AdditionalBaseResponse<RemittanceResponse<RemittanceData>>> => {
  const url = '/remittances'
  const searchParams = new URLSearchParams()

  Object.entries(queryParams).forEach(([key, value]) => {
    if (!isEmpty(value)) {
      searchParams.append(key, String(value))
    }
  })
  const response = await request.get(`${url}?${searchParams.toString()}`, {
    ...getHeaders({ accessToken, locale }),
    cache: 'no-store',
  })

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const deleteRemittance = async ({
  remittanceId,
  accessToken,
}: {
  remittanceId: number
  accessToken?: string
}): Promise<RemittanceResponse<boolean>> => {
  const response = await request.delete(
    `/remittances/${remittanceId}`,
    getHeaders({ accessToken })
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}
