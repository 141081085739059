import { WebRoutes } from '@/shared'
import type { ProfileMenuItem } from './profile.types'

export const ProfileMenuItems: ProfileMenuItem[] = [
  {
    value: 'personal_info',
    title: 'menu.personalInfo',
    icon: 'user',
    href: WebRoutes.Profile,
  },
  {
    value: 'address',
    title: 'menu.manageAddress',
    icon: 'map',
    href: WebRoutes.ProfileAddress,
  },
  {
    value: 'payment_methods',
    title: 'menu.paymentMethods',
    icon: 'payment',
    href: WebRoutes.ProfilePaymentMethods,
  },
  {
    value: 'contact',
    title: 'menu.contacts',
    icon: 'contact',
    href: WebRoutes.ProfileContacts,
  },
  {
    value: 'balance',
    title: 'profile.tabs.balance',
    icon: 'money_bag',
    href: WebRoutes.ProfileBalance,
  },
  { value: 'separator', title: 'separator', icon: 'separator', href: '' },
  {
    value: 'payment_history',
    title: 'menu.paymentHistory',
    icon: 'payment_history',
    href: WebRoutes.ProfilePaymentHistory,
  },
  {
    value: 'identity_documents',
    title: 'remittance.identitiesDocuments',
    icon: 'card',
    href: WebRoutes.ProfileIdentityDocuments,
  },
  {
    value: 'terms',
    title: 'menu.terms',
    icon: 'terms',
    href: WebRoutes.TermsAndCondition,
  },
  {
    value: 'privacy',
    title: 'menu.privacy',
    icon: 'privacy',
    href: WebRoutes.Privacy,
  },
]
