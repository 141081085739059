import { isSuccess, request } from 'libs'
import { getHeaders, isEmpty } from '@/shared'
import type {
  CreateTopupBody,
  CreateTopupResponse,
  DeleteTopupResponse,
  TopupHistoryResponse,
  TopupsServiceResponse,
  TopupsServiceType,
} from './topups.types'

export const getTopupsServices = async ({
  serviceType,
  accessToken,
}: {
  serviceType: TopupsServiceType
  accessToken?: string
}): Promise<TopupsServiceResponse> => {
  const queryParams = new URLSearchParams({ serviceType })

  const url = `/topups/services/?${queryParams.toString()}`

  const response = await request.get(url, {
    ...getHeaders({ accessToken }),
  })

  const data = await response.json()

  return {
    ...data,
    statusCode: response.status,
    success: response.ok,
  }
}

export const createTopup = async ({
  body,
  accessToken,
}: {
  body: CreateTopupBody
  accessToken?: string
}): Promise<CreateTopupResponse> => {
  const url = '/topups'
  const response = await request.post(url, body, {
    ...getHeaders({ accessToken }),
  })

  const data = await response.json()

  return {
    ...data,
    statusCode: response.status,
    success: response.ok,
  }
}

export const getTopupsHistory = async ({
  accessToken,
  queryParams,
}: {
  accessToken?: string
  queryParams: {
    order?: 'ASC' | 'DESC'
    page?: number
    take?: number
  }
}): Promise<TopupHistoryResponse> => {
  const url = '/topups'
  const searchParams = new URLSearchParams()

  Object.entries(queryParams).forEach(([key, value]) => {
    if (!isEmpty(value)) {
      searchParams.append(key, String(value))
    }
  })

  const response = await request.get(`${url}?${searchParams.toString()}`, {
    ...getHeaders({ accessToken }),
    cache: 'no-store',
  })

  const data = await response.json()

  return {
    ...data,
    statusCode: response.status,
    success: isSuccess(response.status),
  }
}

export const deleteTopup = async ({
  topupId,
  accessToken,
}: {
  topupId: number
  accessToken?: string
}): Promise<DeleteTopupResponse> => {
  const url = `/topups/${topupId}`
  const response = await request.delete(url, getHeaders({ accessToken }))
  const data = await response.json()

  return {
    ...data,
    statusCode: response.status,
    success: isSuccess(response.status),
  }
}
