import { getIETFEmailSchema, isValidEmail } from 'libs'
import { z } from 'zod'
import { FormErrorsMessages, NAUTA_DOMAIN } from '@/shared/constants'
import { phoneCubanSchema } from '@/shared/schema'

export const cubacelTopupsSchema = z.object({
  name: z.string().optional(),
  phone: phoneCubanSchema,
  serviceTypeId: z
    .string({
      required_error: FormErrorsMessages.Required,
    })
    .uuid({ message: FormErrorsMessages.InvalidServiceType }),
})

export const nautaTopupsSchema = cubacelTopupsSchema
  .omit({ phone: true })
  .extend({
    email: z
      .string({ required_error: FormErrorsMessages.Required })
      .min(1, { message: FormErrorsMessages.Required })
      .transform((value) => `${value}${NAUTA_DOMAIN}`)
      .refine((value) => isValidEmail(value), FormErrorsMessages.InvalidEmail)
      .pipe(
        getIETFEmailSchema({
          invalidMessage: FormErrorsMessages.InvalidEmail,
          requiredMessage: FormErrorsMessages.Required,
        })
      ),
  })
