import { request, responseAdapter } from 'libs'
import { getHeaders } from '@/shared'
import type {
  ProfileResponse,
  SetDefaultLangResponse,
  UpdateAddressRequest,
  UpdateAddressResponse,
  UpdateEmailRequest,
  UpdateEmailResponse,
  UpdatePersonalInfoResponse,
  UpdatePhoneRequest,
  UpdatePhoneResponse,
  UpdateProfileRequest,
  UpdateProfileResponse,
  UserPersonalInfo,
} from './profile.types'

export const getProfile = async ({
  accessToken,
}: {
  accessToken?: string
}): Promise<ProfileResponse> => {
  const response = await request.get(`/profile`, getHeaders({ accessToken }))

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const updateProfile = async ({
  profile,
  accessToken,
}: {
  profile: UpdateProfileRequest
  accessToken?: string
}): Promise<UpdateProfileResponse> => {
  const response = await request.patch(
    '/profile',
    profile,
    getHeaders({ accessToken })
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const updatePersonalInfo = async ({
  accessToken,
  profile,
}: {
  profile: UserPersonalInfo
  accessToken?: string
}): Promise<UpdatePersonalInfoResponse> => {
  const response = await request.patch(
    '/profile/user',
    profile,
    getHeaders({ accessToken })
  )
  const data = await response.json()
  return responseAdapter({ data, status: response.status })
}

export const updateEmail = async ({
  email,
  accessToken,
}: {
  email: UpdateEmailRequest
  accessToken?: string
}): Promise<UpdateEmailResponse> => {
  const response = await request.patch(
    '/profile/email',
    email,
    getHeaders({ accessToken })
  )

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const updatePhone = async ({
  phone,
  accessToken,
}: {
  phone: UpdatePhoneRequest
  accessToken?: string
}): Promise<UpdatePhoneResponse> => {
  const response = await request.patch(
    '/profile/phone',
    phone,
    getHeaders({ accessToken })
  )
  const data = await response.json()
  return responseAdapter({ data, status: response.status })
}

export const updateAddress = async ({
  address,
  accessToken,
}: {
  address: UpdateAddressRequest
  accessToken?: string
}): Promise<UpdateAddressResponse> => {
  const response = await request.patch(
    '/profile/address',
    address,
    getHeaders({ accessToken })
  )

  const data = await response.json()
  return responseAdapter({ data, status: response.status })
}

export const saveDefaultLanguage = async ({
  accessToken,
  body,
}: {
  body: {
    defaultLan: string
  }
  accessToken: string
}): Promise<SetDefaultLangResponse> => {
  const response = await request.patch(
    '/profile/default-lang',
    body,
    getHeaders({ accessToken })
  )

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}
