import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import type { UserPersonalInfo } from '@/services/profile'

export type RegisterState = {
  user: Omit<UserPersonalInfo, 'gender'> | null
  accessToken: string | null
  refreshToken: string | null
  // TODO: change to enum type
  currentStep: number
}
type RegisterAction = {
  updateCurrentStep: (currentStep: RegisterState['currentStep']) => void
  updateUser: (user: RegisterState['user']) => void
  updateAccessToken: (accessToken: RegisterState['accessToken']) => void
  updateRefreshToken: (refreshToken: RegisterState['refreshToken']) => void
  resetRegisterState: () => void
}

const defaultRegisterState: RegisterState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  currentStep: 1,
}

export const useRegisterStore = create<RegisterState & RegisterAction>()(
  persist(
    (set) => ({
      ...defaultRegisterState,
      resetRegisterState: () => set(defaultRegisterState),
      setAccessToken: (accessToken: string | null) => set({ accessToken }),
      updateCurrentStep: (currentStep) => set(() => ({ currentStep })),
      updateUser: (user) => set(() => ({ user })),
      updateAccessToken: (accessToken) => set(() => ({ accessToken })),
      updateRefreshToken: (refreshToken) => set(() => ({ refreshToken })),
    }),
    {
      name: 'registerStore',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
