import { isSuccess, request } from 'libs'
import { getHeaders } from '@/shared'
import type {
  AddShipmentPackageResponse,
  AddShipmentPackageSchema,
  EditLockerShipmentInfoBody,
  EditShipmentPackageResponse,
  GetLockerAddressResponse,
  GetLockerDeliveryPointsResponse,
  GetLockerShipmentsResponse,
  GetOneLockerShipmentsResponse,
  RemoveShipmentResponse,
  RemoveShipmentSchema,
  SendLockerShipmentInfoBody,
  SendLockerShipmentInfoResponse,
} from './virtual-locker.types'

export const getLockerAddress = async ({
  accessToken,
  locale = 'es',
}: {
  accessToken?: string
  locale?: string
}): Promise<GetLockerAddressResponse> => {
  const url = '/locker/address'

  const response = await request.get(url, getHeaders({ accessToken, locale }))

  const data = await response.json()

  return {
    ...data,
    statusCode: response.status,
    success: isSuccess(response.status),
  }
}

export const getVirtualLockerShipments = async ({
  queryParams,
  accessToken,
  locale = 'es',
}: {
  locale?: string
  accessToken?: string
  queryParams: {
    order?: 'ASC' | 'DESC'
    page?: number
    take?: number
    active: boolean
  }
}): Promise<GetLockerShipmentsResponse> => {
  const { active, order = 'DESC', page = 1, take = 10 } = queryParams
  const searchParams = new URLSearchParams({
    active: `${active}`,
    order,
    page: `${page}`,
    take: `${take}`,
  })

  const url = `/locker/shipment?${searchParams.toString()}`
  const response = await request.get(url, getHeaders({ accessToken, locale }))
  const data = await response.json()
  return {
    ...data,
    statusCode: response.status,
    success: isSuccess(response.status),
  }
}

export const getLockerDeliveryPoints = async (params: {
  accessToken?: string
  locale?: string
  queryParams: {
    countryId: string
    stateId: string
  }
}): Promise<GetLockerDeliveryPointsResponse> => {
  const { queryParams, accessToken, locale } = params
  const searchParams = new URLSearchParams({
    countryId: queryParams.countryId,
    stateId: queryParams.stateId,
  })

  const url = `/locker/delivery-points?${searchParams.toString()}`

  const response = await request.get(url, {
    cache: 'no-store',
    ...getHeaders({ accessToken, locale }),
  })

  const data = await response.json()

  return {
    ...data,
    statusCode: response.status,
    success: isSuccess(response.status),
  }
}

export const createLockerShipmentInfo = async ({
  body,
  accessToken,
  locale = 'es',
}: {
  accessToken?: string
  body: SendLockerShipmentInfoBody
  locale?: string
}): Promise<SendLockerShipmentInfoResponse> => {
  const url = '/locker/shipment'
  const response = await request.post(
    url,
    body,
    getHeaders({ accessToken, locale })
  )

  const data = await response.json()

  return {
    ...data,
    success: isSuccess(response.status),
    statusCode: response.status,
  }
}

export const editLockerShipmentInfo = async (params: {
  accessToken?: string
  body: Partial<EditLockerShipmentInfoBody>
  shipmentId: string
  locale?: string
}): Promise<SendLockerShipmentInfoResponse> => {
  const { body, shipmentId, ...rest } = params

  const url = `/locker/shipment/${shipmentId}`

  const response = await request.patch(url, body, getHeaders(rest))
  const data = await response.json()

  return {
    ...data,
    success: isSuccess(response.status),
    statusCode: response.status,
  }
}

export const addShipmentPackage = async (params: {
  accessToken?: string
  body: AddShipmentPackageSchema
  shipmentId: string
  locale?: string
}): Promise<AddShipmentPackageResponse> => {
  const { body, shipmentId, ...rest } = params

  const url = `/locker/shipment/${shipmentId}/package`

  const response = await request.post(url, body, getHeaders(rest))
  const data = await response.json()

  return {
    ...data,
    success: isSuccess(response.status),
    statusCode: response.status,
  }
}

export const deleteShipmentPackage = async (params: {
  accessToken?: string
  shipmentId: string
  packageId: string
  locale?: string
}): Promise<RemoveShipmentResponse> => {
  const { shipmentId, packageId, ...rest } = params

  const url = `/locker/shipment/${shipmentId}/package/${packageId}`

  const response = await request.delete(url, getHeaders(rest))
  const data = await response.json()

  return {
    ...data,
    success: isSuccess(response.status),
    statusCode: response.status,
  }
}

export const editShipmentPackage = async (params: {
  accessToken?: string
  body: AddShipmentPackageSchema
  shipmentId: string
  packageId: string
  locale?: string
}): Promise<EditShipmentPackageResponse> => {
  const { body, shipmentId, packageId, ...rest } = params

  const url = `/locker/shipment/${shipmentId}/package/${packageId}`

  const response = await request.patch(url, body, getHeaders(rest))
  const data = await response.json()

  return {
    ...data,
    success: isSuccess(response.status),
    statusCode: response.status,
  }
}

export const removeShipment = async (params: {
  accessToken?: string
  shipmentId: string
  locale?: string
  body?: RemoveShipmentSchema
}): Promise<RemoveShipmentResponse> => {
  const { shipmentId, body, ...rest } = params

  const url = `/locker/shipment/${shipmentId}`

  const response = await request.delete(url, getHeaders(rest), body)
  const data = await response.json()

  return {
    ...data,
    success: isSuccess(response.status),
    statusCode: response.status,
  }
}

export const getOneShipment = async ({
  shipmentId,
  accessToken,
  locale = 'es',
}: {
  accessToken?: string
  shipmentId: string
  locale?: string
}): Promise<GetOneLockerShipmentsResponse> => {
  const url = `/locker/shipment/${shipmentId}`

  const response = await request.get(url, {
    cache: 'no-store',
    ...getHeaders({ accessToken, locale }),
  })

  const data = await response.json()

  return {
    ...data,
    statusCode: response.status,
    success: isSuccess(response.status),
  }
}
