import { create } from 'zustand'
import type { Contact } from './contacts.types'

export interface ContactsState {
  currentContact: Contact
  contacts: Contact[]
  showContactForm: boolean
  contactFormType: 'edit' | 'create'
}

export interface ContactsAction {
  addContact: (contact: Contact) => void
  setAllContacts: (contacts: Contact[]) => void
  setCurrentContact: (id: number | null) => void
  updateContact: (contact: Contact) => void
  setShowContactForm: (showContactForm: boolean) => void
  setContactFormType: (contactFormType: 'edit' | 'create') => void
}

export const useContactStore = create<ContactsState & ContactsAction>(
  (set) => ({
    currentContact: {} as Contact,
    contacts: [],
    showContactForm: false,
    contactFormType: 'create',
    addContact: (contact) => {
      set((state) => ({
        contacts: [contact, ...state.contacts],
      }))
    },
    setAllContacts: (contacts) => {
      set(() => ({
        contacts,
      }))
    },
    setCurrentContact: (id: number | null) => {
      set((state) => ({
        currentContact:
          state.contacts.find((contact) => contact.id === id) ||
          ({} as Contact),
      }))
    },
    updateContact: (contact) => {
      set((state) => ({
        contacts: state.contacts.map((c) =>
          c.id === contact.id ? contact : c
        ),
      }))
    },
    setShowContactForm: (showContactForm) => {
      set(() => ({ showContactForm }))
    },
    setContactFormType: (contactFormType) => {
      set(() => ({
        contactFormType,
      }))
    },
  })
)
