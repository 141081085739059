import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import type {
  User,
  UserAddress,
  UserBalance,
  UserLocation,
  UserPersonalInfo,
} from './profile.types'

interface ProfileState {
  user: User | null
}
interface ProfileAction {
  updateUser: (user: ProfileState['user']) => void
  updateBalance: (balance: number) => void
  resetUser: () => void
}

export const useProfileStore = create<ProfileState & ProfileAction>()(
  persist(
    (set) => ({
      user: null,
      resetUser: () => set({ user: null }),
      updateUser: (user) => set(() => ({ user })),
      updateBalance: (balance) =>
        set((state) => ({
          user: {
            user: {
              ...(state.user?.user || ({} as UserPersonalInfo & UserBalance)),
              balance,
            },
            address: state.user?.address || ({} as UserAddress & UserLocation),
          },
        })),
    }),
    {
      name: 'profileUser',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
