import type { AdditionalBaseResponse, BaseApiResponse } from 'libs'
import type { z } from 'zod'
import type {
  addShipmentPackageSchema,
  optionalRemoveShipmentSchema,
  sendLockerShipmentInfoSchema,
} from './virtual-locker.schema'

export const enum ShipmentStatus {
  CREATED = 'CREATED',
  ON_THE_WAY = 'ON_THE_WAY',
  DRAFT = 'DRAFT',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  PROCESSING = 'PROCESSING',
  READY_TO_PAY = 'READY_TO_PAY',
  RECEIVED = 'RECEIVED',
  REMOVED = 'REMOVED',
}

export type LockerAddressData = {
  lockerAddress: string
}

export type GetLockerAddressResponse = AdditionalBaseResponse<
  BaseApiResponse<LockerAddressData>
>

export type LockerShipment = {
  id: number
  receiberId: number | null
  receiberFullName: string | null
  receiberStateId: number | null
  receiberState: string | null
  receiberCityId: number | null
  receiberCity: string | null
  confirmationNumber: string | null
  userIdentityDocumentId: number | null
  status: ShipmentStatus
  deliveryPoint: DeliveryPoint
  packages: Package[]
  transportType: string
  createdAt: string // Date
}

export type LockerDeliveryPoint = {
  id: string
  name: string
  description: string
}

export type LockerPackage = {
  id: number
  trackingCode: string
  note: string
  status: ShipmentStatus
  createdAt: string
}

export type LockerShipmentsData = {
  lockerShipments: LockerShipment[]
  total: number
  page: number
  take: number
  pageCount: number
  hasPreviousPage: boolean
  hasNextPage: boolean
}

export type GetLockerShipmentsResponse = AdditionalBaseResponse<
  BaseApiResponse<LockerShipmentsData>
>
export type GetOneLockerShipmentsResponse = AdditionalBaseResponse<
  BaseApiResponse<LockerShipment>
>

export type DeliveryPoint = {
  id: string
  name: string
  description: string
  state?: string
  code?: string
}

export type LockerDeliveryPointsData = {
  deliveryPoints: DeliveryPoint[]
  total: number
}

export type GetLockerDeliveryPointsResponse = AdditionalBaseResponse<
  BaseApiResponse<LockerDeliveryPointsData>
>

export type SendLockerShipmentSchema = z.infer<
  typeof sendLockerShipmentInfoSchema
>

export type SendLockerShipmentInfoBody = {
  countryId: number
  stateId: number
  cityId: number
  deliveryPointId: string
  note?: string
  transportType: string
}

export type SendLockerShipmentInfoData = LockerShipment

export type Package = {
  id: number
  trackingCode: string
  note?: string
  status: ShipmentStatus
  createdAt: string // Date
}

export type SendLockerShipmentInfoResponse = AdditionalBaseResponse<
  BaseApiResponse<SendLockerShipmentInfoData>
>

export type AddShipmentPackageSchema = z.infer<typeof addShipmentPackageSchema>

export type AddShipmentPackageData = {
  id: number
  trackingCode: string
  note: string
  status: ShipmentStatus
  createdAt: string // Date
}

export type AddShipmentPackageResponse = AdditionalBaseResponse<
  BaseApiResponse<AddShipmentPackageData>
>

export type EditShipmentPackageResponse = AddShipmentPackageResponse

export type DeleteShipmentPackageResponse = AdditionalBaseResponse<
  BaseApiResponse<true>
>

export type EditLockerShipmentInfoBody = {
  countryId: number
  stateId: number
  cityId: number
  deliveryPointId: string
  receiveContactId: number
  note: string
  transportType: string
  userIdentityDocumentId: number
  create: boolean
}

export type RemoveShipmentSchema = z.infer<typeof optionalRemoveShipmentSchema>

export type RemoveShipmentResponse = AdditionalBaseResponse<
  BaseApiResponse<true>
>
